@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./AvenirNextLTPro-Bold.otf') format('opentype');
  /* font-weight: 700; */
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./AvenirNextLTPro-Demi.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Next LT Pro';
  src: url('./AvenirNextLTPro-Regular.otf') format('opentype');
  /* font-weight: 400; */
  font-weight: normal;
  font-style: normal;
}