.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  background: #fff;
  padding-bottom: 20%;
  font-family: 'Avenir Next LT Pro';
}

@media screen and (min-width: 481px) {
  .wrapper {
    display: none;
  }
}

.img_logo {
  max-width: 70%;
  height: auto;
}

.wrapper_icon {
  background: rgba(136, 161, 59, 0.09);
  border-radius: 50%;
  height: 180px;
  width: 180px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.message {
  color: #595959;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-left: 1rem;
  margin-right: 1rem;
}

.bold {
  font-weight: bold;
}
